import { ApolloClient, InMemoryCache } from '@apollo/client';
import { httpLink } from './links/http-link';

export const publicClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          gallery: (_, opt) => opt.toReference({ __typename: 'GalleryModel', id: opt.args!.galleryId }),
        },
      },
    },
  }),
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' }, react: { suspense: { autoDisposeTimeoutMs: 2000 } } },
});
